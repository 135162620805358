import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { Destination, MeetravelUser, Travel as _TravelOld, TravelService, TravelStatuses, UserType } from 'meetravel-ui-lib';
import { AclService, CrudService, MessageService, Search } from 'elbuild-ui-lib-core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { DialogService } from 'elbuild-ui-componentslib';
import { AdminFeatures } from '../../../conf/features';
import { SmartTravelService, TravelTransaction } from 'src/app/@core/data/smart-travel.service';

class Facility {
	facility: string;
	id: number;
	insdate: number;
	travelid: number;
}

class Travel extends _TravelOld {
  smart: boolean;
  price: number;
  priceCreator: string;
  minguests: number;
  maxtimegoal: number;
  facilities: Facility[] | string[];
  bookingDetail: string;
}



@Component({
  selector: 'app-travel-smart',
  templateUrl: './travel-smart.component.html',
  styleUrls: ['./travel-smart.component.scss']
})
export class TravelSmartComponent implements OnInit {

	@Input('travel')
	set travel(value: Travel) {
		this._travel = value;
		TravelSmartComponent.FACILITIES.forEach(facility => this.facilitiesMap[facility] = false);
		this._travel.facilities.forEach(facility => this.facilitiesMap[facility.facility] = true);

	}

	static FACILITIES = ['Alloggio', 'Pranzi/Cene', 'Esperienze', 'Trasporti', 'Noleggio', 'Ingressi'];
	facilitiesMap = {} as Record<string, boolean>;

	_travel: Travel;
	feature_editable = false;
	FEATURE_EDIT_VIAGGI = AdminFeatures.ADMIN_EDIT_VIAGGI;
	private _transactions: TravelTransaction[];
	private _transactionsFiltered: TravelTransaction[];
	loading: boolean = false;
	transactionType: any;
	expireDate: string;

	constructor(private crudService: CrudService<Travel>,
		private travelService: TravelService,
		private messageService: MessageService,
		private translateService: TranslateService,
		private aclService: AclService,
		private router: Router,
		private dialogService: DialogService,
		private smartTravelService: SmartTravelService,
	) {
	}

	get facilities() {
		return TravelSmartComponent.FACILITIES;
	}

	onClick(key, value) {
		this.facilitiesMap[key] = !value;
		console.log(this.facilitiesMap);
	}

	ngOnInit() {
		this.aclService.hasPermission(this.FEATURE_EDIT_VIAGGI).subscribe(res => {
			this.feature_editable = res;
		})

		this.loadTransactions();
		this._travel.priceCreator = (this._travel.price / 1.15).toFixed(2);
		this.expireDate = new Date(this._travel.enddate.valueOf() - this._travel.maxtimegoal * (24*3600*1000)).toLocaleDateString();
	}

	loadTransactions() {
		this.loading = true;
		this.smartTravelService
			.getSmartTravelTransactions(this._travel.id)
			.subscribe(data => {
				this._transactions = this._transactionsFiltered = data;
			});
		this.loading = false;
	}

	get transactions() {
		return this._transactionsFiltered.map(transaction => { return { ...transaction, insdate: new Date(transaction.insdate*1000)} })
	}

	submit() {
		this._travel.facilities = Object.keys(this._travel.facilities).filter(facility => this.facilitiesMap[facility]);
		//this._travel.facilities =
		console.log("FINAL TRAVEL OBJECT", this._travel);
		this.crudService.saveEntity(Travel, 'travel', this._travel).subscribe(result => {
			this._travel = result;
			this.messageService.sendSuccess(this.translateService.instant('labels.save-success'), this.translateService.instant('labels.travel'));
		}, error => {
			this.messageService.sendError(error);
		});
	}

	transactionTypeChange(event: string) {
		console.log(event);
		if(event && event[0] !== 'ALL')
			this._transactionsFiltered = this._transactions.filter(item => item.status === event[0]);
		else
		this._transactionsFiltered = this._transactions;
	}

	priceChange(event: number) {
		console.log(event);
		this._travel.priceCreator = (event / 1.15).toFixed(2);
	}

	expireDateChange(event:number) {
		console.log(event);
		let enddate = this._travel.enddate.valueOf();
		this.expireDate = (new Date(enddate - event * (24*3600*1000))).toLocaleDateString();

	}
}
